












import { Component, Inject, Vue, Watch } from "vue-property-decorator";
import AnalyticsPowerBIService, { EmbededConfig } from "@/services/analytics-powerbi-service"
import * as pbi from "powerbi-client"
@Component({
})
export default class AnalyticsBeta extends Vue {

@Inject() AnalyticsPowerBIService!: AnalyticsPowerBIService;

DashboardConfig!: EmbededConfig
loading = true;
powerbi = window.powerbi

async mounted() {
    const { reportid } = this.$route.query
    if(reportid){
        this.DashboardConfig = await this.AnalyticsPowerBIService.getDashboardById(reportid as string)
    }else{
        this.DashboardConfig = await this.AnalyticsPowerBIService.getDashboard();
    }
    
    this.loading = false;
}

getPowerBiConfig(){
    const report = this.DashboardConfig.EmbedReport[0];
    const models = pbi.models;

    return {
        type: "report",
        embedUrl: report.EmbedUrl,
        accessToken: this.DashboardConfig.EmbedToken.token, 
        reportId : report.ReportId,
        tokenType: models.TokenType.Embed,
        settings: {
            background: models.BackgroundType.Transparent
        }
    }
}

@Watch("loading")
loadDashboard(){
    const config = this.getPowerBiConfig();

    const report = this.powerbi.embed(this.$refs["pbi"] as HTMLElement, config)

    report.off("loaded");
    report.off("rendered");
    report.off("error");
    
}

}
